
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.main-banner-inner {
    width: 100%;
    max-width: 1280px;
    padding-right: 0;
    padding-left: 0;
    @media screen and (min-width: 1024px) {
        padding-right: 26px;
        padding-left: 26px;
    }
}
.image {
    width: 100%;
    max-width: 50%;
    display: none;
    @media screen and (min-width: 1024px) {
        display: block;
    }
}

.main-banner {
    width: 100vw;
    min-height: calc(100vh - 58px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    padding: 30px 20px;
    background-image: url("/assets/images/backgroundImg.jpg");
    background-position: bottom right;
    background-repeat: no-repeat;
    background-color: #e5e8f7;
    padding-bottom: 200px;
    background-size: 130%;
    @include md {
        padding-bottom: 300px;
        background-size: 100%;
    }
    @include lg {
        background-size: 45%;
        padding: 60px;
        background-position: 500px right;
    }
    @include xl {
        background-size: 65%;
        padding: 40px;
    }
    @include xxl {
        background-size: 55%;
    }

    .form {
        z-index: 5;
        padding: 30px 24px;
        width: auto;
        // max-width: 630px;
        border-radius: 20px;
        background-color: white;
        box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.35);
        border-radius: 5px;
        min-height: 500px;
        display: flex;
        flex-direction: column;
        position: relative;
        // margin-top: 6vh;/
        @media screen and (min-width: 1024px) {
            width: 630px;
        }
        .steps-inner {
            flex-grow: 1;
        }
        h1 {
            &.step-title {
                font-size: 20px;
                font-weight: bold !important;
                margin: 0;
                @include lg {
                    font-size: 30px;
                }
            }
        }
        .step-title {
            text-align: center;
            font-size: 24px !important;
            font-weight: 500 !important;
            margin-bottom: 10px;
            @include lg {
                font-size: 28px !important;
            }
        }
        .label-as-step-title {
            text-align: center;
            font-weight: 500 !important;
            margin-bottom: 10px;
            margin-top: -20px;
            margin-bottom: 25px !important;
            font-size: 24px !important;
            @include lg {
                margin-left: -50px;
                margin-right: -50px;
                font-size: 28px !important;
            }
        }
        .step-description {
            text-align: center;
            font-weight: 300;
            margin-bottom: 2rem;
            font-size: 14px;
            @include lg {
                font-size: 18px;
            }
        }
        .fields-wrapper {
            margin-top: 1.5rem;
        }
        .form-button {
            max-width: unset !important;
            margin: 0 auto !important;
            margin-top: 5px !important;
            border-radius: 3px !important;
            background: linear-gradient(180deg, #ffd79a 0%, #fcbe60 100%);
            border-radius: 10px !important;
            color: #000;
            transition: all 0.2s ease-in-out;
            @include lg {
                max-width: 416px !important;
                font-size: 1.125rem;
                line-height: 1.75rem;
            }
        }
        .form-field {
            // margin-top: 2rem;
            max-width: unset;
            margin: 0 auto;
            margin-bottom: 0.5rem;
            @include lg {
                max-width: 416px;
            }
            > input {
                border-radius: 5px !important;
            }
        }
        .radio-field {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            margin-top: 10px;
            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;
                input {
                    &:checked + label {
                        border: 2px solid black;
                        filter: brightness(1.1);
                    }
                }
                label {
                    background: linear-gradient(
                        180deg,
                        #ffd79a 0%,
                        #fcbe60 100%
                    );
                    border-radius: 10px !important;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    flex-direction: row;
                    min-height: 50px;
                    font-weight: 500;
                    color: #000;

                    & > span {
                        margin-top: 0;
                    }
                    & > div {
                        margin-right: 20px;
                        margin-left: 20px;
                        img {
                            width: 100%;
                            // filter: brightness(0) invert(1);
                        }
                    }
                    &:hover {
                        filter: brightness(1.1);
                    }
                    @include md {
                        > span {
                            @apply text-lg;
                        }
                        min-height: 62px;
                    }
                    @apply text-left;
                }
                @include lg {
                    margin: 0 10px;
                    margin-bottom: 15px;
                    width: 100%;
                }
            }
            @include lg {
                margin: 0 -10px;
            }
        }
        .tcpa {
            margin-bottom: 10px;
        }
    }
}

.step-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 345px;
}
.back-button {
    left: 40px;
    @media screen and (max-width: 1023px) {
        left: 25px;
    }
    @media screen and (max-width: 414px) {
        left: 0;
    }
}
